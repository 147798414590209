<template>
  <section>
    <v-container class="fill-height">
      <v-row align="center" justify="center">
        <v-col sm="12" md="8" lg="6" xl="5">
          <v-card>
            <v-card-title class="px-9">
              <v-card class="mt-n9" color="accent" width="100%" elevation="6">
                <v-card-text class="text-center pa-3">
                  <v-img
                    :src="require('../../assets/Allec-New-Logo-A.png')"
                    class="mx-auto"
                    width="9rem"
                    contain
                    alt="Allec Logo"
                    transition="scale-transition"
                  />

                </v-card-text>
              </v-card>
            </v-card-title>
            <v-card-text class="pa-6">
              <login-form></login-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import LoginForm from "@/components/auth/LoginForm.vue";
export default {
  name: "SignIn",
  data() {
    return {
    };
  },
  components: {
    LoginForm,
  },
};
</script>

<style scoped>
/* section {
  background: url(https://image.freepik.com/psd-gratis/marketing-digital-conexion-wifi-5g_23-2148386087.jpg)
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
} */
</style>